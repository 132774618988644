<template>
  <div class="sample-header flex flex-row flex-nowrap items-center space-x-2">
    <div :class="iconClass" class="flex-shrink-0">
      <img :src="ref(sample.icon)" class="w-full h-full yb-body-text stroke-current">
    </div>
    <div class="font-semibold flex-grow">
      {{ sample.name }}
    </div>
    <slot />
  </div>
</template>

<script>
import YbSampleMixin from './SampleMixin'

export default {
  mixins: [
    YbSampleMixin
  ],
  props: {
    sample: Object,
    iconClass: {
      type: String,
      default: 'w-12 h-12'
    }
  }
}
</script>

<style>
.dark .sample-header img {
  filter: invert(1);
}
</style>
