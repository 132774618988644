<template>
  <div class="yb-sample-catalog w-full h-full pl-4">
    <div v-show="branch !== 'master'" v-if="!String(branch).match(/release/)" class="mt-4">
      <strong class="font-semibold text-red-500">Branch&nbsp;</strong><span class="underline">{{ branch }}</span>
    </div>
    <div v-if="selected" class="yb-view">
      <div class="yb-view-header relative pt-4">
        <a class="yb-link font-normal absolute top-0 left-0" @click="selected = null">&lt; Back to sample data catalog</a>
        <yb-sample-header :sample="selected" icon-class="w-6 h-6" class="mt-6">
          <div class="flex flex-row justify-end space-x-2">
            <a
              v-for="(action, index) in selected.actions"
              :key="index"
              class="cursor-pointer border-solid border py-0 px-1 yb-link-border hover:bg-yb-brand-primary hover:text-white rounded-sm select-none"
              @click="load(selected.id, action.name, action.script, action.scripts, selected.database, selected.schema )"
            >{{ action.name }}</a>
          </div>
        </yb-sample-header>
        <yb-markdown class="text-sm my-2" :source="selected.description" :relative-url="ref(selected.id)" />
        <hr class="mt-4 !mx-0">

        <a
          :href="`https://github.com/YellowbrickData/YmSamples/tree/${branch}/${selected.id}`"
          class="absolute cursor-pointer -mt-1 right-0 w-40 h-40 z-1 overflow-hidden"
          target="_blank"
          rel="noopener"
        >
          <fork-me class="w-full h-full" alt="Fork me on GitHub" />
        </a>
      </div>

      <tabs class="yb-view-content">
        <tab title="Summary" class="yb-view relative" data-test-id="summary">
          <yb-error class="yb-view-header mt-2" :show-error="error != null" :error-message="formatError(error)" @close="error = null" />
          <div class="yb-view-content-auto pb-24">
            <yb-markdown class="w-full" :source="selected.readme" :relative-url="ref(selected.id)" />
          </div>
        </tab>
        <tab v-if="selectedFiles && selectedFiles.length > 0" title="Files" class="yb-view">
          <div class="yb-view-header mt-2">
            <yb-search-input v-model="filesSearch" placeholder="Search Files" input-classes="w-full py-1 px-2 yb-input-base" />
            <yb-error class="yb-view-header" :show-error="error != null" :error-message="formatError(error)" @close="error = null" />
          </div>
          <yb-grid
            ref="grid"
            class="ag-theme-alpine yb-view-content mt-2 pb-8 select-auto"
            :rows="selectedFiles"
            no-rows-to-show="No files to display"
            :search-expression="filesSearch"
            :column-defs="filesColumns"
          />
        </tab>
      </tabs>
    </div>
    <div v-else class="yb-view">
      <div class="yb-view-content-auto">
        <div class="text-lg mb-4">
          Discover and explore sample data from the Sample Data Catalog.  You can setup, load and query any of these sample datasets for free.
        </div>
        <div class="grid gap-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-2">
          <div
            v-for="(sample, index) in samples"
            v-show="!sample.hidden"
            :key="index"
            class="yb-view p-6 cursor-pointer border border-yb-gray-lighter hover:border-yb-selection hover:shadow rounded-lg h-96 max-h-96"
            @click="selected = sample"
          >
            <yb-sample-header class="yb-view-header" :sample="sample">
              <div class="yb-bg-indicator border-solid border border-gray-400 py-0.5 px-1 mr-2 rounded-sm font-light leading-tight select-none text-right flex-shrink-0 self-start">
                {{ bytes(sample.size) }}
              </div>
            </yb-sample-header>
            <yb-markdown class="yb-view-content mt-2 font-light overflow-ellipsis overflow-hidden" :source="sample.description" :relative-url="ref()" />
          </div>
        </div>
      </div>
    </div>

    <transition name="dialog">
      <yb-modal
        v-if="selectedContent"
        show
        :title="'View ' + selectedContentName"
        dialog-classes="relative"
        data-test-id="show-example-content"
        @close="selectedContent = false"
      >
        <button v-yb-clipboard:text="selectedContent" class="absolute top-16 right-6 yb-button-default mt-4">
          <yb-icon class="yb-button-icon" icon="clipboard" />
          <translate>Copy to Clipboard</translate>
        </button>
        <yb-highlight language="sql" :content="selectedContent" class="overflow-auto whitespace-pre-wrap size-v-60 w-full" />
      </yb-modal>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import yaml from 'js-yaml'
import YbSampleHeader from './SampleHeader.vue'
import YbSampleMixin from './SampleMixin'
import YbSampleActions from './SampleActions.vue'
import * as YbGridCellRenderers from '@/components/YbGridCellRenderers'
import ForkMe from '@/assets/svg/forkme_right_gray_6d6d6d.svg'
import { bytes } from '@/filters'
import { formatError } from '@/services/app.js'

const YbScampleActionsCellRenderer = defineComponent(YbSampleActions)

export default {
  components: {
    YbSampleHeader,
    ForkMe
  },
  mixins: [
    YbSampleMixin
  ],
  props: {
    show: Boolean
  },
  data() {
    return {
      samples: [],
      selected: null,
      selectedFiles: [],
      selectedContent: null,
      selectedContentName: null,
      filesSearch: '',
      error: null,
      filesColumns: [
        {
          headerName: 'Name',
          field: 'name',
          minWidth: 350,
          flex: 10,
          cellRenderer: YbGridCellRenderers.YbGridLinkCellRenderer,
          cellRendererParams: {
            icon: 'file_sql',
            clicked: _ => this.load(this.selected.id, _.name, _.download_url, null, this.selected.database, this.selected.schema)
          }
        },
        { headerName: 'Size', field: 'size', minWidth: 100, maxWidth: 125, flex: -1, cellRenderer: YbGridCellRenderers.YbGridBytes },
        {
          headerName: '',
          field: '',
          minWidth: 200,
          flex: 1,
          cellRenderer: YbScampleActionsCellRenderer,
          cellRendererParams: {
            view: _ => this.view(this.selected.id, _.name, _.download_url),
            query: _ => this.load(this.selected.id, _.name, _.download_url, null, this.selected.database, this.selected.schema)
          }
        }
      ]
    }
  },
  watch: {
    selected(_) {
      if (_) {
        this.selectedFiles = null
        this.retrieveFiles(_)
        this.retrieveReadme(_)
      }
    }
  },
  async beforeMount() {
    try {
      const registry = await this.getContent('registry.yaml')
      this.samples = yaml.loadAll(registry).map((sample) => {
        sample.readme = null
        return sample
      })
    } catch (e) {
      this.error = e
    }
  },
  methods: {
    bytes,
    formatError,
    async retrieveReadme(sample) {
      if (!sample.readme) {
        sample.readme = '(loading...)'
        const readme = await this.getContent(sample.index || sample.id + '/README.md')
        sample.readme = readme
      }
    },
    async retrieveFiles(sample) {
      if (this.selectedFiles === null) {
        this.selectedFiles = []
        sample.files.forEach((files) => {
          const pattern = files.include ? new RegExp(files.include) : /.*/
          try {
            this.getJson(files.dir)
              .then((filesList) => {
                this.selectedFiles.push(...filesList.filter(f => f.name.match(pattern)))
              })
          } catch (e) {
            this.error = e
          }
        })
      }
    },
    async load(id, name, file, files, database, schema) {
      // Get the content for this.
      let content
      if (file) {
        content = await this.getContent(file)
      } else if (!!files && Array.isArray(files)) {
        const contents = await Promise.all(files.map(file => this.getContent(file)))
        content = contents.join('\n')
      }
      if (!content) {
        this.$dialogs.warn(`Could not locate content for ${name}`)
        return
      }
      this.$store.set('query-editor/load', { name: `${id}: ${name}`, content, database, schema })

      // Go to query editor if not there already.
      if (!this.$route.path.match(/\/query/)) {
        this.$router.push('/query')
      }

      // And we're done.
      this.$emit('close')
    },

    async view(id, name, url) {
      this.selectedContentName = name
      this.selectedContent = await this.getContent(url)
    }
  }
}
</script>

<style scoped lang="postcss">

.yb-sample-catalog :deep(.ag-theme-alpine) div.ag-row-hover .yb-sample-actions {
  @apply flex;
}

.yb-sample-catalog :deep(.prose) h1,
.yb-sample-catalog :deep(.prose) h2,
.yb-sample-catalog :deep(.prose) h3,
.yb-sample-catalog :deep(.prose) h4 {
  @apply border-b border-yb-gray-lightest pb-1 mb-1;
}

.dark .yb-sample-catalog :deep(.prose) h1,
.dark .yb-sample-catalog :deep(.prose) h2,
.dark .yb-sample-catalog :deep(.prose) h3,
.dark .yb-sample-catalog :deep(.prose) h4 {
  @apply border-yb-gray-medium;
}

.yb-sample-catalog :deep(.prose),
.yb-sample-catalog :deep(.prose) strong {
  @apply yb-body-text;
}

.dark .yb-sample-catalog :deep(.prose) blockquote {
  @apply text-yb-gray-lighter;
}

.yb-sample-catalog :deep(.prose) ul {
  @apply my-1.5;
}

.yb-sample-catalog :deep(.prose) ul li {
  @apply my-0.5;
}

.yb-sample-catalog :deep(.prose) ul li::before {
  @apply bg-yb-gray-medium;
}

.dark .yb-sample-catalog :deep(.prose) ul li::before {
  @apply bg-yb-gray-lighterer;
}

.dark .yb-sample-catalog :deep(.prose) ol li::before {
  @apply text-yb-gray-faintest;
}

.yb-sample-catalog :deep(.prose) table {
  @apply mt-1.5 mb-3 w-max border border-yb-gray-lighterer border-collapse text-base;
}

.dark .yb-sample-catalog :deep(.prose) table {
  @apply border-yb-gray-medium;
}

.yb-sample-catalog :deep(.prose) table th {
  @apply bg-transparent text-center font-semibold;

}

.yb-sample-catalog :deep(.prose) table th,
.yb-sample-catalog :deep(.prose) table td {
  @apply p-2 border-r border-b border-yb-gray-lighterer;
}

.dark .yb-sample-catalog :deep(.prose) table th,
.dark .yb-sample-catalog :deep(.prose) table td {
  @apply text-yb-gray-faintest border-yb-gray-medium;
}

.yb-sample-catalog :deep(.prose) table tr:nth-child(even) {
  @apply bg-yb-gray-faint;
}

.dark .yb-sample-catalog :deep(.prose) table tr:nth-child(even) {
  @apply bg-yb-gray-mud;
}

.dark .yb-sample-catalog :deep(.prose) code {
  @apply text-yb-gray-faintest;
}

.yb-sample-catalog :deep(.prose) code:not([class*="language-"]) {
  @apply bg-yb-gray-faint;
  @apply font-normal;
}
.dark .yb-sample-catalog :deep(.prose) code:not([class*="language-"]) {
  @apply bg-yb-gray-mud;
}

.yb-sample-catalog :deep(.prose) code::before,
.yb-sample-catalog :deep(.prose) code::after {
  content: '';
}

</style>
