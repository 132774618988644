const DEFAULT_BRANCH = import.meta.env.VITE_SAMPLE_CATALOG_BRANCH || 'master'
export default {
  data() {
    return {
      branch: DEFAULT_BRANCH
    }
  },
  methods: {
    ref(uri) {
      return `https://raw.githubusercontent.com/YellowbrickData/YmSamples/${this.branch}/${uri}`
    },
    async getJson(uri) {
      const response = await this.getResource(uri)
      return response.json()
    },
    async getContent(uri) {
      const response = await this.getResource(uri)
      return response.text()
    },
    async getResource(uri) {
      // Get GitHub repository file info
      if (uri.match(/^http/)) {
        return fetch(uri)
      } else {
        return fetch(`https://api.github.com/repos/YellowbrickData/YmSamples/contents/${uri}?ref=${this.branch}`, {
          headers: {
            Accept: 'application/vnd.github.v3.raw'
          }
        })
      }
    }
  }
}
