<template>
  <yb-grid-actions>
    <yb-button class="yb-link" icon="file_view" @click="view">
      View
    </yb-button>
    <yb-button class="yb-link" icon="browser-brakets" @click="query">
      Query
    </yb-button>
  </yb-grid-actions>
</template>

<script>
import YbGridActions from '@/components/grid/YbGridActions.vue'

export default {
  components: {
    YbGridActions
  },
  methods: {
    view() {
      if (this.params.view) {
        this.params.view(this.params.data)
      }
    },
    query() {
      if (this.params.query) {
        this.params.query(this.params.data)
      }
    }
  }
}
</script>
